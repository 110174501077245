<template>
   <div v-if="timeDiff" class="text-gray-700 text-sm">
      It will be removed from your calendar once confirmed. You can always come
      back and book a new session.
   </div>
   <div v-else class="text-gray-700 text-sm">
      Since the scheduling of an appointment involves the reservation of time
      set aside especially for you, a minimum of 12 hours notice is required for
      scheduling or cancelation of an appointment. If a session is canceled less
      than 12 hours prior, 1 Live session Credit will be charged.
   </div>
   <div class="flex gap-3 justify-end mt-4">
      <BaseButton @click="emits('close')" variant="outlined" text-color="black">
         No, keep session
      </BaseButton>
      <BaseButton @click.prevent="cancelSession"> Yes, cancel </BaseButton>
   </div>
</template>
<script setup>
   import dayjs from 'dayjs';
   import { useTherapistSessionStore } from '~/stores/session/therapist-session';
   const props = defineProps({
      selectedSession: {
         type: Object,
         required: true,
      },
   });
   const emits = defineEmits(['success', 'close']);
   const timeDiff = () => {
      const sessionDateTime = dayjs(
         `${selectedSession?.value?.date} ${this.selectedSession?.value?.time}`
      );
      const hoursDiff = sessionDateTime.diff(
         dayjs(selectedSession?.value?.date),
         'hour'
      );
      return hoursDiff < 12;
   };
   const busy = ref(false);
   const cancelSession = async () => {
      busy.value = true;
      let { data } = await useTherapistSessionStore().cancelSessionAsTherapist(
         props.selectedSession?.id
      );

      if (data?.value) {
         await emits('success');
      }
      busy.value = false;
   };
</script>
