import apiRoutes from '@/const/apiRoutes';
import type { SessionResponse } from '~/types/SessionResponse';

export const useTherapistSessionStore = defineStore(
   'therapistSessionStore',
   () => {
      const { setSnackbarDetail } = useSnackbarStore();

      const cancelSessionAsTherapist = async (sessionId: string) => {
         const { data, error } = await useTherapistApi<SessionResponse>(
            apiRoutes?.therapist?.sessions?.cancel(sessionId),
            {
               method: 'DELETE',
            }
         );
         if (data.value?.data) {
            setSnackbarDetail({
               show: true,
               title: 'Successfully canceled session',
               type: 'success',
            });
            return;
         }
         if (error?.value) {
            setSnackbarDetail({
               show: true,
               title: error.value.data?.message,
               type: 'error',
            });
         }
         return { data, error };
      };

      return {
         cancelSessionAsTherapist,
      };
   }
);
