<template>
   <div v-if="loading" class="flex justify-center items-center min-h-96">
      <BaseLoading size="w-20 h-20" />
   </div>
   <div v-else class="px-8 py-4 w-auto h-full min-h-screen">
      <div class="border-b-2 border-b-gray-25/80 pb-5 mb-5 w-auto">
         <div class="text-lg flex justify-between font-medium">
            <div>
               Session with
               <span class="text-primary-500">{{
                  sessionDetail?.client?.name || '-'
               }}</span>
            </div>
            <XCircleIcon
               @click="$emit('close')"
               class="h-6 cursor-pointer w-6"
            ></XCircleIcon>
         </div>
         <div
            class="bg-gray-25/15 border border-gray-25 p-3 w-1/2 rounded-lg mt-4"
         >
            <p class="text-sm font-medium mb-3">Session Details</p>
            <CommonDateAndTimeView
               v-if="sessionDetail?.date && sessionDetail?.time"
               :date="`${sessionDetail?.date}t${sessionDetail?.time}`"
            ></CommonDateAndTimeView>
         </div>
      </div>

      <div class="border-b-2 border-b-gray-25/80 pb-5 mb-5">
         <div class="text-lg flex justify-between items-center font-medium">
            <p>Client Information</p>
            <BaseButton
               color="primary-500"
               class="bg-opacity-40 rounded-lg"
               @click="
                  navigateTo(`/therapist/client/${sessionDetail?.client?.id}`)
               "
            >
               <p class="text-primary-500 font-medium me-3">View profile</p>
               <ArrowTopRightOnSquareIcon
                  class="h-4 w-4 text-primary-500"
               ></ArrowTopRightOnSquareIcon>
            </BaseButton>
         </div>
         <div
            v-if="
               sessionDetail &&
               sessionDetail?.client &&
               Object.keys(sessionDetail?.client).length
            "
            class="flex flex-wrap justify-between bg-gray-25/30 p-3 rounded-lg mt-4"
         >
            <div class="w-1/2 lg:w-1/3 mb-4">
               <p class="pb-2 text-xs text-gray-200">Username</p>
               <p class="text-sm text-black font-medium">
                  {{ sessionDetail?.client?.name }}
               </p>
            </div>
            <div class="w-1/2 lg:w-1/3 mb-4">
               <p class="pb-2 text-xs text-gray-200">Language Preferences</p>
               <div class="text-sm text-black font-medium max-w-48 text-wrap">
                  <span
                     v-for="(language, index) in sessionDetail?.preferences
                        ?.languages"
                     :key="language"
                     class="ms-1"
                  >
                     {{ language }}
                     {{
                        (
                           sessionDetail?.preferences?.languages?.length !=
                           index + 1
                        ) ?
                           ','
                        :  ''
                     }}
                  </span>
               </div>
            </div>
            <div class="w-1/2 lg:w-1/3 mb-4">
               <p class="pb-2 text-xs text-gray-200">Therapy Type</p>
               <BaseButton
                  size="sm"
                  class="rounded-full"
                  variant="outlined"
                  :color="'primary-500'"
                  :class="'bg-primary-600/10 border border-green-600'"
                  style="padding: 5px 10px"
               >
                  <CheckCircleIcon
                     class="h-5 w-5 me-3 text-primary-500"
                  ></CheckCircleIcon>
                  <p class="text-xs font-medium">
                     {{ sessionDetail?.preferences?.therapy_type }}
                  </p>
               </BaseButton>
            </div>
            <div class="w-1/2 lg:w-1/3 mb-4">
               <p class="pb-2 text-xs text-gray-200">Gender</p>
               <p class="text-sm text-black font-medium">
                  {{ sessionDetail?.client?.gender || '-' }}
               </p>
            </div>

            <div class="w-1/2 lg:w-1/3 mb-4">
               <p class="pb-2 text-xs text-gray-200">Age Group</p>
               <p class="text-sm text-black font-medium">
                  {{ sessionDetail?.preferences?.age_group || '-' }}
               </p>
            </div>
            <div class="w-1/2 lg:w-1/3 mb-4"></div>
         </div>
      </div>
      <div class="border-b-2 border-b-gray-25/80 pb-5 mb-5">
         <p class="text-lg font-medium">Subscription Information</p>
         <div class="bg-gray-25/30 p-3 rounded-lg mt-4">
            <template v-if="sessionDetail?.subscription?.type">
               <div class="flex gap-8 mb-4">
                  <div>
                     <p class="pb-2 text-xs text-gray-200">Subscription Type</p>
                     <p class="text-sm capitalize text-black font-medium">
                        <span
                           v-if="
                              sessionDetail?.subscription?.type?.includes('_')
                           "
                        >
                           {{
                              sessionDetail?.subscription?.type
                                 ?.split('_')
                                 ?.join(' ')
                           }}
                        </span>
                        <span v-else>
                           {{ sessionDetail?.subscription?.type }}
                        </span>
                     </p>
                  </div>
                  <div>
                     <p class="pb-2 text-xs text-gray-200">Start Date</p>
                     <p class="text-sm text-black font-medium">
                        {{
                           sessionDetail?.subscription?.start ||
                           sessionDetail?.date
                        }}
                     </p>
                  </div>
               </div>
               <div class="mb-4">
                  <p class="pb-2 text-xs text-gray-200">Sessions Credits</p>
                  <p class="text-sm text-black capitalize font-medium">
                     {{
                        sessionDetail?.subscription?.type != 'out_of_pocket' ?
                           sessionDetail?.subscription?.type
                        :  'Subscription'
                     }}
                     <span class="ms-1">Credits</span>
                     <span
                        v-if="sessionDetail?.subscription?.credits"
                        class="bg-secondary-50 capitalize text-secondary-500 p-2"
                        >{{ sessionDetail?.subscription?.credits }}</span
                     >
                     <template v-if="sessionDetail?.subscription?.recurring">
                        Per

                        <span
                           class="bg-secondary-50 sm:mt-0 w-24 rounded-xl px-4 text-secondary-500 p-2"
                           >{{
                              (
                                 sessionDetail?.subscription?.recurring ===
                                 'month'
                              ) ?
                                 'month'
                              :  'year'
                           }}</span
                        >
                     </template>
                  </p>
               </div>
            </template>
            <div v-else class="min-h-20 text-gray-200 text-sm">
               No Active Subscription
            </div>
         </div>
      </div>
      <div v-if="showAction" class="mb-5">
         <div>
            <div class="font-medium text-lg">Manage Session</div>
         </div>

         <div v-if="showAction" class="flex gap-2 items-center mt-4">
            <BaseButton
               variant="outlined"
               class="rounded-lg"
               :to="`/therapist/chat/${sessionDetail?.conversation_id}`"
            >
               <ChatBubbleLeftEllipsisIcon
                  class="w-4 h-4 me-3 text-primary-500"
               ></ChatBubbleLeftEllipsisIcon>
               Chat with client
            </BaseButton>
            <BaseButton @click="showReschedule = true" class="rounded-lg">
               <CalendarDaysIcon class="w-4 h-4 me-3"></CalendarDaysIcon>
               Reschedule
            </BaseButton>
            <BaseButton
               variant="plain"
               color="orange-500"
               @click="showCancelAction"
            >
               Cancel Session
            </BaseButton>
         </div>
      </div>
   </div>
   <BaseDialog
      v-model:open-dialog="showReschedule"
      size="lg"
      :title="
         sessionDetail ? 'Reschedule Session' : (
            $t('sessions.bookSessionForClient')
         )
      "
   >
      <CalendarNewAvailability
         @close="(showReschedule = false), $emit('close')"
         :selected-session="sessionDetail"
         from-modal
         from-booking-page
         show-confirmation
      ></CalendarNewAvailability>
   </BaseDialog>
   <BaseDialog
      v-model:open-dialog="openCancelSession"
      title="Are you sure you want to cancel the live session?"
   >
      <TherapistCancelModal
         @close="openCancelSession = false"
         @success="cancelSession"
         :selectedSession="{ id: sessionId }"
      ></TherapistCancelModal>
   </BaseDialog>
</template>
<script>
   import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/20/solid';
   import {
      ArrowRightIcon,
      ArrowTopRightOnSquareIcon,
      ArrowUpRightIcon,
      CalendarDaysIcon,
      CalendarIcon,
      CheckCircleIcon,
      ClipboardDocumentListIcon,
      ClockIcon,
      GlobeAltIcon,
      PlusIcon,
      XCircleIcon,
   } from '@heroicons/vue/24/outline';
   import dayjs from 'dayjs';
   import { mapActions } from 'pinia';
   import { useTherapistApi } from '~/composables/therapistApi';
   import apiRoutes from '~/const/apiRoutes.js';

   export default {
      props: {
         sessionInformation: {
            type: Object,
            default() {
               return null;
            },
         },
         showAction: {
            type: Boolean,
            default: false,
         },
         fromCalendar: {
            type: Boolean,
            default: false,
         },
         sessionId: {
            type: String,
            default: '',
         },
      },
      components: {
         CalendarIcon,
         GlobeAltIcon,
         XCircleIcon,
         ClockIcon,
         ClipboardDocumentListIcon,
         CalendarDaysIcon,
         ArrowUpRightIcon,
         CheckCircleIcon,
         ArrowRightIcon,
         PlusIcon,
         ArrowTopRightOnSquareIcon,
         ChatBubbleLeftEllipsisIcon,
      },
      computed: {
         timeDiff() {
            const sessionDateTime = dayjs(
               `${this.sessionDetail.date} ${this.sessionDetail?.time}`
            );
            const hoursDiff = sessionDateTime.diff(
               dayjs(this.sessionDetail?.date),
               'hour'
            );
            return hoursDiff < 12;
         },
      },
      data() {
         return {
            showReschedule: false,
            sessionDetail: {},
            openCancelSession: false,
            loading: false,
            busy: false,
         };
      },
      created() {
         if (!this.sessionInformation) {
            this.getSessionDetail();
         } else {
            this.sessionDetail = { ...this.sessionInformation };
         }
      },
      methods: {
         ...mapActions(useSnackbarStore, ['setSnackbarDetail']),
         showCancelAction() {
            if (this.fromCalendar) {
               this.openCancelSession = true;
            } else {
               this.$emit('cancel-session', this.sessionDetail);
            }
         },
         async getSessionDetail() {
            if (this.loading) return;
            this.loading = true;
            let { data, error } = await useTherapistApi(
               apiRoutes?.therapist?.sessions?.overview(this.sessionId),
               {
                  method: 'GET',
               }
            );
            if (data?.value) {
               this.sessionDetail = data?.value;
               this.loading = false;
            }
            if (error?.value) {
               this.loading = false;
            }
         },

         async cancelSession() {
            this.openCancelSession = false;
            this.$emit('reload');
         },
      },
   };
</script>
